import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Card from "../components/Card"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Form, Button } from '@themesberg/react-bootstrap';
import Grid from '../components/Grid'
import { navigate } from "gatsby"
import Parse from '../vendor/Parse'
const { useEffect, } = React;
const Profile1 = "images/2021-01-30.jpg";
const IndexPage = () => {


  const [items, setItems] = React.useState([])
  const getAllEvents = () => {
    return Parse.Cloud.run('eventList')
  }

  useEffect(() => {
    getEvent()
  }, [])

  const getEvent = async () => {
    const items = await getAllEvents();
    setItems(items)
  }



  return <Layout>
    <Grid classes="col-12 col-xl-4">
      {
        items?.map(item => <Card
          cover={item.photo}
          avatar={Profile1}
          title={"Neil Sims"}
          subtitle={"sub"}
          text="sdfds"
        />)
      }

    </Grid>
  </Layout >
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
